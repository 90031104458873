import React from 'react';

import StickyAnchorSubNav from './StickyAnchorSubNav';

import { useScrollOnClick } from '@sprinklr/shared-lib/hooks/useScrollOnClick';

const StickyAnchorWrapper = ({
  subNavMenuGroup,
  variant,
  headerOffset,
  styles,
  parentSubNavStyles,
  subNavLinkStyles,
  selectedLinkStyles,
}) => {
  const { onAnchorClick, selectedAnchorLinkIndex } = useScrollOnClick({
    subNavMenuGroup,
    headerOffset,
  });

  return (
    <StickyAnchorSubNav
      styles={styles}
      parentSubNavStyles={parentSubNavStyles}
      subNavLinkStyles={subNavLinkStyles}
      selectedLinkStyles={selectedLinkStyles}
      menuGroup={subNavMenuGroup}
      onClick={onAnchorClick}
      anchorLinkIndex={selectedAnchorLinkIndex}
      variant={variant}
    />
  );
};

export default StickyAnchorWrapper;
