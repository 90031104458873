import React, { useCallback } from 'react';

import { Link } from '@sprinklr/shared-lib/components/link';
import { Box, Flex, Themed } from 'theme-ui';

import { useScrollOnClick } from '@sprinklr/shared-lib/hooks/useScrollOnClick';

export const MobileHeaderList = ({
  subNavMenuGroup,
  closeOverlay,
  headerOffset,
}) => {
  const { onAnchorClick, selectedAnchorLinkIndex } = useScrollOnClick({
    subNavMenuGroup,
    headerOffset,
  });

  const handleAnchorClick = useCallback(
    (event, index) => {
      onAnchorClick(index);
      event.preventDefault();
      closeOverlay();
    },
    [onAnchorClick],
  );

  return (
    <Box sx={{ height: '100%' }}>
      {subNavMenuGroup.map((menu, index) => (
        <Box sx={{ paddingX: ['24px', '48px', '80px'] }}>
          <Box
            sx={{
              paddingTop: '24px',
              paddingBottom: '12px',
              borderBottom: '2px solid #DBDBDB',
            }}
          >
            <Link
              href={menu.link}
              onClick={event => handleAnchorClick(event, index)}
              linkSx={{ cursor: 'pointer', textDecoration: 'none' }}
            >
              <Themed.h4
                style={{
                  margin: 0,
                  color:
                    selectedAnchorLinkIndex === index ? 'black' : '#A0A0A8',
                  textTransform: 'uppercase',
                }}
              >
                {menu.label}
              </Themed.h4>
            </Link>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
