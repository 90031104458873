export const checkIsIOS = () => {
  if (typeof window !== `undefined`) {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator?.userAgentData?.platform || navigator?.platform) ||
      // iPad on iOS 13 detection
      (navigator?.userAgent?.includes('Mac') && 'ontouchend' in document)
    );
  }

  return;
};

// Inspired by this answer https://stackoverflow.com/a/9039885
// May be necessary to update as new versions of iOS are released as can be seen by needing iOS 13+ fallback
