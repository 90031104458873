import React from 'react';

import { IconButton } from 'theme-ui';
import { Link } from '@sprinklr/shared-lib';

import SprinklrIcon from '@sprinklr/shared-lib/assets/svgs/sprinklr_clr.svg';

const ICON_SVG_STYLES = {
  width: '100%',
};

export const SprinklrIconButton = () => (
  <Link
    href="https://www.sprinklr.com/"
    variant="unstyled"
    styledLinkSx={{ cursor: 'default', display: 'flex', marginRight: '45px' }}
  >
    <IconButton
      sx={{
        width: ['40px', '50px'],
        height: ['30px', '40px'],
        flexShrink: 0,
        cursor: 'pointer',
      }}
    >
      <SprinklrIcon style={ICON_SVG_STYLES} />
    </IconButton>
  </Link>
);
