import React, { useEffect, useState } from 'react';

import { Box } from 'theme-ui';
import { MobileHeaderList } from './MobileHeaderList';

import { useIOSHeight } from '@sprinklr/shared-lib/hooks/useIOSHeight';

import { MOBILE_HEADER_HEIGHT, TABLET_HEADER_HEIGHT } from '../constants';

const HEADER_OFFSET = 80;

export const MobileHeaderOverlay = ({ subNavMenuGroup, closeOverlay }) => {
  const { iOSHeight, isIOS } = useIOSHeight();

  return (
    <Box
      sx={{
        width: '100%',
        position: 'absolute',
        bg: 'background',
        zIndex: 31,
        height: [
          isIOS
            ? `calc(${iOSHeight}px - ${MOBILE_HEADER_HEIGHT}px)`
            : `calc(100vh - ${MOBILE_HEADER_HEIGHT}px)`,
          isIOS
            ? `calc(${iOSHeight}px - ${TABLET_HEADER_HEIGHT}px)`
            : `calc(100vh - ${TABLET_HEADER_HEIGHT}px)`,
        ],
        top: [`${MOBILE_HEADER_HEIGHT}px`, `${TABLET_HEADER_HEIGHT}px`],
        overflow: 'auto',
        paddingTop: '30px',
      }}
    >
      <MobileHeaderList
        subNavMenuGroup={subNavMenuGroup}
        closeOverlay={closeOverlay}
        headerOffset={HEADER_OFFSET}
      />
    </Box>
  );
};
