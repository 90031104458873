/** @jsx jsx */
import { useRef, useState, useLayoutEffect, useContext, useMemo } from 'react';
import { Link } from '@sprinklr/shared-lib/components/link';
import { Container } from '@sprinklr/shared-lib';
import { useDebounce } from '@sprinklr/shared-lib/hooks';
import PageContext from '@sprinklr/shared-lib/context/pageContext';
import { Box, Flex, jsx } from 'theme-ui';
import {
  subnavStyles,
  getGrandParentStyles,
  getSubnavLinkStyles,
  getLeftFadeStyles,
  getRightFadeStyles,
  getSubNavParentStyles,
} from './styles';
import { VARIANTS } from './variants';
import { HeaderHeightListener } from '@sprinklr/shared-lib/context/HeaderHeightContext';

const StickyAnchorSubNav = ({
  menuGroup,
  onClick,
  anchorLinkIndex,
  variant = 'V1',
  styles,
  parentSubNavStyles,
  subNavLinkStyles,
  selectedLinkStyles,
}) => {
  const handleClick = (event, index) => {
    event.preventDefault();
    if (onClick) onClick(index);
  };

  const subnavRef = useRef();
  const subnavParentRef = useRef();

  const [fullSubnavWidth, setFullSubnavWidth] = useState(0);
  const [subnavParentWidth, setSubnavParentWidth] = useState(0);
  const [leftScrollPosition, setLeftScrollPosition] = useState(0);
  const { isHeaderMinimal } = useContext(PageContext);

  const grandParentStyles = useMemo(
    () => ({
      ...getGrandParentStyles(variant, isHeaderMinimal),
      ...parentSubNavStyles,
    }),
    [parentSubNavStyles, variant, isHeaderMinimal],
  );

  // Throttling inspo: https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event#Example
  let isThrottling = false;
  subnavRef?.current?.addEventListener('scroll', () => {
    if (!isThrottling) {
      window.requestAnimationFrame(function () {
        setLeftScrollPosition(subnavRef?.current?.scrollLeft);
        isThrottling = false;
      });
      isThrottling = true;
    }
  });

  useLayoutEffect(() => {
    setFullSubnavWidth(subnavRef?.current?.scrollWidth);
    setSubnavParentWidth(subnavParentRef?.current?.scrollWidth);
  }, [subnavRef, subnavParentRef]);

  // Need debounce to handle page re-size event rate
  useLayoutEffect(() => {
    const debouncedHandleResize = useDebounce(function handleResize() {
      setFullSubnavWidth(subnavRef?.current?.scrollWidth);
      setSubnavParentWidth(subnavParentRef?.current?.scrollWidth);
    }, 1000);

    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  const hasScrolledToEnd =
    // Give some wiggle room for measurements which are slightly off due to fractions of pixels
    subnavParentWidth + leftScrollPosition >= fullSubnavWidth - 10 &&
    subnavParentWidth + leftScrollPosition <= fullSubnavWidth + 10;

  // Scroll the link to center of viewport if overflowing
  const linksRef = useRef([]);

  useLayoutEffect(() => {
    linksRef.current.find((el, i) => {
      if (i === anchorLinkIndex) {
        // Position from left side of parent container - (1/2 visible container width - 1/2 element width without margin)
        const measuredLeft =
          el.offsetLeft - (subnavParentWidth / 2 - (el.offsetWidth - 45) / 2);
        subnavRef?.current?.scroll({
          top: 0,
          left: measuredLeft,
          behavior: 'smooth',
        });
      }
    });
  }, [linksRef, anchorLinkIndex]);

  const isHeaderVariant = variant === VARIANTS.HEADER;

  return (
    <HeaderHeightListener>
      <Box sx={grandParentStyles}>
        <Container
          containerSx={{ position: 'relative', py: 0, ...styles }}
          fullWidth={isHeaderVariant ? true : false}
        >
          <Box sx={getLeftFadeStyles({ variant, leftScrollPosition })} />
          <Flex ref={subnavParentRef} sx={getSubNavParentStyles(variant)}>
            <Flex sx={subnavStyles} ref={subnavRef}>
              {menuGroup.map(({ label, anchorLink }, index) => {
                const isLastItem = menuGroup.length === index + 1;
                const isSelected = index === anchorLinkIndex;
                const selectedStyles = isSelected ? selectedLinkStyles : {};

                return (
                  <span key={index} ref={el => (linksRef.current[index] = el)}>
                    <Link
                      linkSx={{
                        ...getSubnavLinkStyles({
                          variant,
                          isLastItem,
                          isSelected,
                        }),
                        ...subNavLinkStyles,
                        ...selectedStyles,
                      }}
                      href={anchorLink}
                      onClick={event => handleClick(event, index)}
                    >
                      {label}
                    </Link>
                  </span>
                );
              })}
            </Flex>
          </Flex>
          <Box sx={getRightFadeStyles({ variant, hasScrolledToEnd })} />
        </Container>
      </Box>
    </HeaderHeightListener>
  );
};

export default StickyAnchorSubNav;
