import React from 'react';

import { CallToAction } from '@sprinklr/shared-lib';
import { Box, Flex } from 'theme-ui';
import StickyAnchorWrapper from '../../stickyAnchorSubNav/StickyAnchorWrapper';
import { SprinklrIconButton } from './SprinklrIconButton';

const HEADER_OFFSET = 100;

export const DesktopHeader = ({
  className,
  ctas,
  subNavMenuGroup,
  variant = 'HEADER',
  subNavStyles,
  parentSubNavStyles,
  subNavLinkStyles,
  selectedLinkStyles,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        ...className,
      }}
    >
      <Flex sx={{ alignItems: 'center' }}>
        <SprinklrIconButton />
        <StickyAnchorWrapper
          styles={subNavStyles}
          parentSubNavStyles={parentSubNavStyles}
          subNavMenuGroup={subNavMenuGroup}
          subNavLinkStyles={subNavLinkStyles}
          selectedLinkStyles={selectedLinkStyles}
          variant={variant}
          headerOffset={HEADER_OFFSET}
        />
      </Flex>
      <Box>
        {ctas.map(cta => {
          return <CallToAction {...cta} variant="primary" key={cta.id} />;
        })}
      </Box>
    </Box>
  );
};
