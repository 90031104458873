import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import useWindowSize from 'react-use/lib/useWindowSize';
import useWindowScroll from 'react-use/lib/useWindowScroll';
import { isWindowDefined } from '../utils/isWindowDefined';

const getId = url => url?.split('#')?.[1];
const DEFAULT_HEADER_OFFSET = 150;

type SubNavMenuGroup = { anchorLink: string; label: string; link: string }[];
type UseAnchorClickReturn = {
  onAnchorClick: (index: number) => void;
  selectedAnchorLinkIndex: number | null;
};

export const useScrollOnClick = ({
  subNavMenuGroup,
  headerOffset = DEFAULT_HEADER_OFFSET,
}: {
  subNavMenuGroup: SubNavMenuGroup;
  headerOffset?: number;
}): UseAnchorClickReturn => {
  const [anchorLinkIndex, setAnchorLinkIndex] = useState(null);
  const [sectionMap, setSectionMap] = useState(null);
  const [isNavByClickInProgress, setIsNavByClickInProgress] = useState(false);

  const { y: windowY } = useWindowScroll();
  const { width: windowWidth } = useWindowSize();

  useLayoutEffect(() => {
    const calculateSectionMap = () => {
      const sectionMap = subNavMenuGroup.reduce((prev, curr, index) => {
        const id = getId(curr?.anchorLink);
        const targetElement = document?.getElementById(getId(curr?.anchorLink));
        return {
          ...prev,
          [id]: { rect: targetElement?.getBoundingClientRect(), index },
        };
      }, {});

      setSectionMap(sectionMap);
    };

    setTimeout(() => calculateSectionMap(), 100);
  }, [windowWidth, windowY]);

  useEffect(() => {
    const activeSection =
      sectionMap &&
      Object.keys(sectionMap)?.find(sectionId => {
        const sectionRect = sectionMap[sectionId]?.rect;
        return (
          sectionRect?.y < headerOffset + 1 &&
          sectionRect?.y + sectionRect?.height > headerOffset + 1
        );
      });

    sectionMap?.[activeSection]?.index === anchorLinkIndex &&
      isNavByClickInProgress &&
      setIsNavByClickInProgress(false);
    !isNavByClickInProgress &&
      setAnchorLinkIndex(sectionMap?.[activeSection]?.index);
  }, [sectionMap]);

  useEffect(() => {
    if (
      isWindowDefined() &&
      anchorLinkIndex !== null &&
      anchorLinkIndex !== undefined
    ) {
      const id = getId(subNavMenuGroup[anchorLinkIndex]?.anchorLink);
      window.history.pushState(null, null, `#${id}`);
    }
  }, [anchorLinkIndex]);

  const handleAnchorClick = useCallback(
    index => {
      setAnchorLinkIndex(index);
      setIsNavByClickInProgress(true);
      setTimeout(() => {
        const url = subNavMenuGroup?.[index]?.anchorLink;
        const targetElement = document?.getElementById(getId(url));
        if (targetElement) {
          const elementPosition = targetElement.getBoundingClientRect().top;
          const offsetPosition = windowY + elementPosition - headerOffset;
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      }, 10);
    },
    [windowWidth, windowY],
  );

  return {
    onAnchorClick: handleAnchorClick,
    selectedAnchorLinkIndex: anchorLinkIndex,
  };
};
