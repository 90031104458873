import { useState, useEffect } from 'react';

import { checkIsIOS } from '@sprinklr/shared-lib/utils/checkIsIOS';

export const useIOSHeight = () => {
  const [iOSHeight, setiOSHeight] = useState(0);

  // iOS does not work correctly with vh so need to measure it as a backup
  // https://medium.com/quick-code/100vh-problem-with-ios-safari-92ab23c852a8
  const isIOS = checkIsIOS();

  useEffect(() => {
    if (isIOS) {
      const appHeight = () => setiOSHeight(window.innerHeight);
      window.addEventListener('resize', appHeight);
      appHeight();
    }
  }, [setiOSHeight, isIOS]);

  return { isIOS, iOSHeight };
};
