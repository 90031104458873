import { ThemeUIStyleObject } from 'theme-ui';

type Variant = 'HEADER' | 'GREY_HEADER';

export function setHeaderStyles(hasScrolled: boolean): ThemeUIStyleObject {
  const baseStyles: ThemeUIStyleObject = {
    width: '100%',
    position: 'sticky',
    top: '0px',
    zIndex: '20',
    paddingY: '16px',
    backgroundColor: 'white',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  };

  if (hasScrolled) {
    baseStyles.boxShadow = '0px 8px 14px 0px rgba(0, 0, 0, 0.1)';
  }

  return baseStyles;
}
