import React, { useCallback, useEffect, useState } from 'react';

import { CallToAction } from '@sprinklr/shared-lib';
import Button from '@sprinklr/shared-lib/components/button';
import { Flex } from 'theme-ui';
import { MobileHeaderOverlay } from './components/MobileHeaderOverlay';

import { SprinklrIconButton } from '../SprinklrIconButton';

import CloseIcon from '@sprinklr/shared-lib/assets/svgs/close.svg';
import HamburgerIcon from '@sprinklr/shared-lib/assets/svgs/hamburger.svg';

export const MobileHeader = ({ className, ctas, subNavMenuGroup }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const toggleOpen = useCallback(() => {
    setOpen(isOpenPreviously => !isOpenPreviously);
  }, []);
  const closeOverlay = useCallback(() => setOpen(false), []);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : '';
  }, [isOpen]);

  return (
    <>
      <Flex
        sx={{
          paddingX: ['24px', '48px', '80px'],
          width: '100%',
          alignItems: 'center',
          ...className,
        }}
      >
        <SprinklrIconButton />
        <Flex
          sx={{ flexGrow: 1, justifyContent: 'flex-end', alignItems: 'center' }}
        >
          {ctas.map(cta => {
            return <CallToAction {...cta} variant="primary" key={cta.id} />;
          })}
          <Button
            onClick={toggleOpen}
            buttonSx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              border: 'none',
              padding: '0px',
              width: '30px',
              height: '30px',
              cursor: 'pointer',
              marginLeft: ['12px', '18px', '24px'],
            }}
          >
            {isOpen ? (
              <CloseIcon width="16px" />
            ) : (
              <HamburgerIcon width="16px" />
            )}
          </Button>
        </Flex>
      </Flex>
      {isOpen ? (
        <MobileHeaderOverlay
          subNavMenuGroup={subNavMenuGroup}
          closeOverlay={closeOverlay}
        />
      ) : null}
    </>
  );
};
