import React, { useMemo } from 'react';
import { Box } from 'theme-ui';

import useHasScrolled from '@sprinklr/shared-lib/templates/headerTemplate/useHasScrolled';

import { DesktopHeader } from './components/DesktopHeader';
import { MobileHeader } from './components/mobileHeader';
import { setHeaderStyles } from './utils';

const EMPTY_ARRAY = [];

export const SinglePageHeader = ({
  navItems,
  ctas = EMPTY_ARRAY,
  styles,
  subNavStyles,
  parentSubNavStyles,
  subNavLinkStyles,
  selectedLinkStyles,
}) => {
  const subNavMenuGroup = useMemo(
    () => navItems.map(item => ({ ...item, anchorLink: item.link })),
    [navItems],
  );

  const hasScrolled = useHasScrolled(20);

  return (
    <Box sx={{ ...setHeaderStyles(hasScrolled), ...styles }}>
      <Box
        sx={{
          maxWidth: '1360px',
          mx: 'auto',
          width: '100%',
        }}
      >
        <DesktopHeader
          subNavMenuGroup={subNavMenuGroup}
          ctas={ctas ?? []}
          className={{ display: ['none', null, null, null, 'flex'] }}
          subNavStyles={subNavStyles}
          parentSubNavStyles={parentSubNavStyles}
          subNavLinkStyles={subNavLinkStyles}
          selectedLinkStyles={selectedLinkStyles}
        />
        <MobileHeader
          subNavMenuGroup={subNavMenuGroup}
          ctas={ctas ?? []}
          className={{ display: ['flex', null, null, null, 'none'] }}
        />
      </Box>
    </Box>
  );
};
