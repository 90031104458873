import { VARIANTS } from './variants';

export const getGrandParentStyles = (
  variant: string,
  isHeaderMinimal?: boolean,
) => {
  const baseGrandparentStyles = {
    width: '100%',
    zIndex: 20,
    position: 'sticky',
    display: ['none', 'block'],
    p: 0,
  };
  if (variant === VARIANTS.HEADER) {
    return {
      ...baseGrandparentStyles,
      top: '0px',
      backgroundColor: 'white',
      width: '100%',
    };
  }
  if (variant === VARIANTS.V2) {
    return {
      ...baseGrandparentStyles,
      top: [
        'var(--header-height,96px)',
        null,
        null,
        null,
        isHeaderMinimal
          ? 'var(--header-height,96px)'
          : 'var(--header-height,70px)',
      ],
      backgroundColor: 'white',
      borderBottomWidth: '4px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'primary',
    };
  }

  return {
    ...baseGrandparentStyles,
    top: [
      'var(--header-height,96px)',
      null,
      null,
      null,
      isHeaderMinimal
        ? 'var(--header-height,96px)'
        : 'var(--header-height,70px)',
    ],
    backgroundColor: 'rgba(241, 241, 241, 1)',
  };
};

const sharedFadeStyles = {
  position: 'absolute',
  top: 0,
  zIndex: 2,
  height: '100%',
  width: '90px',
  pointerEvents: 'none',
};

export const getLeftFadeStyles = ({ variant, leftScrollPosition }) => {
  if (
    variant === VARIANTS.V2 ||
    variant === VARIANTS.HEADER ||
    variant === VARIANTS.GREY_HEADER
  )
    return undefined;
  return {
    ...sharedFadeStyles,
    left: '80px',
    background:
      'linear-gradient(90deg, rgba(241, 241, 241, 1), rgba(241, 241, 241, 0) 100%)',
    transition: '.1s opacity',
    opacity: leftScrollPosition > 0 ? 1 : 0,
  };
};

export const getRightFadeStyles = ({ variant, hasScrolledToEnd }) => {
  if (
    variant === VARIANTS.V2 ||
    variant === VARIANTS.HEADER ||
    variant === VARIANTS.GREY_HEADER
  )
    return undefined;
  return {
    ...sharedFadeStyles,
    right: '80px',
    background:
      'linear-gradient(270deg, rgba(241, 241, 241, 1), rgba(241, 241, 241, 0) 100%)',
    transition: '.1s opacity',
    opacity: hasScrolledToEnd ? 0 : 1,
  };
};

export const subnavStyles = {
  position: 'relative',
  justifyContent: 'flex-start',
  overflow: 'scroll',
  scrollbarWidth: 'none',
  scrollBehavior: 'smooth',
  '::-webkit-scrollbar': {
    display: 'none',
  },
};

export const getSubnavLinkStyles = ({ variant, isSelected, isLastItem }) => {
  const baseSubnavLinkStyles = {
    py: '20px',
    display: 'inline-block',
    textDecoration: 'none',
    fontSize: [1, 1, 1, 2],
    fontWeight: 'bold',
    letterSpacing: '1.2px',
    whiteSpace: 'nowrap',
    transition: '.1s color, .1s border-bottom-color',
    '&:hover': {
      color: 'primary',
    },
  };

  const borderStyles = {
    borderBottomWeight: '4px',
    borderBottomStyle: 'solid',
    borderBottomColor: isSelected ? 'primary' : 'transparent',
  };

  if (variant === VARIANTS.V2) {
    return {
      ...baseSubnavLinkStyles,
      color: isSelected ? 'primary' : '#000000',
      marginRight: !isLastItem && '45px',
    };
  }

  if (variant === VARIANTS.HEADER) {
    return {
      ...baseSubnavLinkStyles,
      ...borderStyles,
      my: '20px',
      py: '0px',
      textTransform: 'uppercase',
      color: isSelected ? 'black' : 'mediumGrey',
      marginRight: !isLastItem && '45px',
      '&:hover': {
        color: 'black',
      },
      borderBottomColor: isSelected ? 'black' : 'transparent',
    };
  }

  if (variant === VARIANTS.GREY_HEADER) {
    return {
      ...baseSubnavLinkStyles,
      ...borderStyles,
      my: '20px',
      py: '0px',
      textTransform: 'uppercase',
      color: isSelected ? '#fff' : '#fff',
      marginRight: !isLastItem && '45px',
      '&:hover': {
        color: '#fff',
      },
      borderBottomColor: isSelected ? '#fff' : 'transparent',
      lineHeight: '15px',
      letterSpacing: '2.5px',
      fontSize: 0,
    };
  }

  return {
    ...baseSubnavLinkStyles,
    ...borderStyles,
    textTransform: 'uppercase',
    color: isSelected ? 'primary' : 'mediumGrey',
    marginRight: !isLastItem && '45px',
  };
};

export const getSubNavParentStyles = variant => {
  if (variant === VARIANTS.V2) {
    return undefined;
  }
  return { justifyContent: 'center' };
};
